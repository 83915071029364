@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  font-family: 'Open Sans', 'Segoe UI', Tahoma, sans-serif;
  color: #231f20;
  /* Background image */
  background-image: url('../public/background1080.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  /* Primary color */
  --primary-color: #215f69;
}
