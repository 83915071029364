/* copied from MuiInputBase-input-MuiOutlinedInput-input */
/* with slight modifications */
#Phone-Number-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 8.5px 14px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 0px 4px 4px 0px;
}

#Phone-Number-input:hover {
  border-color: rgba(0, 0, 0, 0.87);
}

#Phone-Number-input:focus-visible {
  outline-color: var(--primary-color);
  border-width: 1px;
  border-radius: 0px 4px 4px 0px;
}

.PhoneInputCountry {
  border: 1px rgba(0, 0, 0, 0.23) solid;
  border-radius: 4px 0px 0px 4px;
  padding-right: 5px;
  padding-left: 5px;
  margin-right: 0px;
}

.PhoneInputCountry:hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.PhoneInputCountry:focus-visible {
  outline-color: var(--primary-color);
}

/* Case: when select country has focus */
.PhoneInputCountry:focus-within {
  border: 2px solid var(--primary-color);
}

.phone-error > * {
  border-color: red !important;
  outline-color: red;
}
