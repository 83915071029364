.terms-conditions::-webkit-scrollbar {
  height: 2rem;
  width: 0.4rem;
}

.terms-conditions::-webkit-scrollbar-track {
  background-color: rgb(245, 245, 245);
  border-radius: 10px;
}

.terms-conditions::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  border-radius: 10px;
}
